import * as React from "react"
import {Box, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {sectionPadding, sectionSpacing} from "../theme";
import {graphql} from "gatsby";
import {Bouwtype} from "../graphqlTypes";
import Plattegronden from "../components/bouwtype/plattegronden";
import {ReactNode} from "react";
import {Button} from "gatsby-material-ui-components";
import {bouwtypeSort} from "../utils/utils";
import Situatie from "../components/situatie";
import {GatsbyImage, getImage, StaticImage} from "gatsby-plugin-image";
import BouwtypeTitle from "../components/bouwtype/title";
import Bouwnummers from "../components/bouwnummers";
import Specificaties from "../components/bouwtype/specificaties";
import Prijs from "../components/prijs";
import {Slide} from "../components/slideshow";

interface Props {
    data: pageData
}
const BouwtypeTemplate = ({data}: Props) => {
    const {bouwtype} = data
    const prijsVanaf = bouwtype.algemeen?.koopaanneemsomVanaf
    const image = bouwtype.hoofdfoto && getImage(bouwtype.hoofdfoto?.localFile)
    const interieur = bouwtype.fotos?.find(foto => foto.titel === 'Interieur')
    const interieurFoto = interieur?.localFile && getImage(interieur.localFile)
    const tweedeFoto = bouwtype.fotos && getImage(bouwtype.fotos[0].localFile)
    return (
      <article>
          {image &&
            <GatsbyImage
              image={image}
              alt={'water'}
              style={{
                  maxHeight: 600,
              }}
            />
          }
          <Container maxWidth={'lg'}>
              <Box
                marginY={sectionSpacing}
              >
                  <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                          <BouwtypeTitle
                            title={bouwtype.children[0].frontmatter.group}
                            subtitle={bouwtype.algemeen.omschrijving || ''}
                          />
                      </Grid>
                      <Grid item xs={12} md={3}>
                          <Typography
                            fontWeight={'bold'}
                          >
                              Bouwnummers:
                          </Typography>
                          <Box
                            marginY={1}
                          >
                              <Bouwnummers bouwnummers={bouwtype.bouwnummers || []} />
                          </Box>
                      </Grid>
                      <Grid item xs={12} md={3}>
                          <Typography
                            fontWeight={'bold'}
                          >
                              Specificaties:
                          </Typography>
                          <Box
                            marginY={1}
                          >
                              <Specificaties bouwtype={bouwtype} />
                          </Box>
                      </Grid>
                  </Grid>
                  <Stack
                    direction={'row'}
                    spacing={4}
                    alignItems={'center'}
                  >
                      <Divider
                        sx={{
                            width: 'calc(100% - 300px)'
                        }}
                      />
                      <Typography
                        color={'primary.main'}
                        fontWeight={'bold'}
                        fontSize={'1.5rem'}
                        width={300}
                        noWrap
                      >
                          V.A. <Prijs prijs={prijsVanaf || 0} /> v.o.n.
                      </Typography>
                  </Stack>
              </Box>
              <Box marginBottom={sectionSpacing}>
                  <Grid container spacing={sectionSpacing}>
                      <Grid
                        item
                        xs={12}
                        md={8}
                      >
                          <Typography
                            paragraph
                            fontSize={24}
                            fontWeight={'medium'}
                          >
                              {bouwtype.children[0]?.frontmatter.intro}
                          </Typography>
                      </Grid>
                  </Grid>
              </Box>
              <Box marginY={sectionSpacing}>
                  <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        md={7}
                        order={{md: 2}}
                      >
                          {interieurFoto ?
                            <GatsbyImage
                              image={interieurFoto}
                              alt={'interieur'}
                            />
                            :
                            tweedeFoto &&
                            <GatsbyImage
                              image={tweedeFoto}
                              alt={'interieur'}
                              />
                          }
                      </Grid>
                      <Grid item xs={12} md={5}>
                          <Paper
                            sx={{
                                backgroundColor: 'secondary.main',
                                padding: 4,
                                height: '100%',
                                color: 'common.white'
                            }}

                          >
                              <List>
                                  {bouwtype.children[0]?.frontmatter.kenmerken.map((kenmerk: string, index: number) =>
                                    <ListItem key={index} disablePadding>
                                        <ListItemIcon>
                                            <StaticImage src={'../images/bullit.svg'} alt={''}/>
                                        </ListItemIcon>
                                        <ListItemText
                                          disableTypography
                                          primary={<Typography fontFamily={'Finlandica'}>{kenmerk}</Typography>}
                                        />
                                    </ListItem>
                                  )}
                              </List>
                          </Paper>
                      </Grid>
                  </Grid>

              </Box>
              <Box
                marginY={sectionSpacing}
                textAlign={'center'}
              >
                  <Typography variant={'h3'} marginBottom={2}>
                      Indeling
                  </Typography>
                  <Plattegronden bouwtype={bouwtype} />
              </Box>
              <Box
                marginY={sectionPadding}
              >
                  <Slide items={bouwtype.fotos?.map(foto => getImage(foto.localFile)) || []} />
              </Box>
              <Box
                marginY={sectionPadding}
              >
                  <Situatie bouwnummers={bouwtype.bouwnummers?.filter(bouwnummer => bouwnummer.fields.coordinaten) || []} />
              </Box>
          </Container>
      </article>
    )
}

interface pageData {
    bouwtype: Bouwtype
    allBouwtype: {
        nodes: Bouwtype[]
    }
}

export const query = graphql`
    fragment BouwtypeTemplate on Bouwtype {
        children {
          id
          ... on MarkdownRemark {
            id
            frontmatter {
              intro
              kenmerken
              group
            }
            html
          }
        }
        algemeen {
            omschrijving
            koopaanneemsomVanaf
        }
        hoofdfoto {
            localFile {
                childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH,
                      aspectRatio: 1.6,
                      width: 1600
                )
                }
            }
            omschrijving
        }
        ...BouwtypePlattegronden
        ...BouwtypeSpecificaties
        fotos {
            localFile {
                childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH,
                      aspectRatio: 1.6,
                      width: 1600
                )
                }
            }
            omschrijving
        }
        bouwnummers {
        ...BouwnummerArea
        }
    }
    query($objectcode: String!) {
        bouwtype(diversen: {objectcode: {eq: $objectcode}}) {
            ...BouwtypeTemplate
        }
        allBouwtype(filter: {diversen: {objectcode: {ne: $objectcode}}}) {
            nodes {
                algemeen {
                    omschrijving
                }
                fields {
                    slug
                }
            }
        }
    }
`

export default BouwtypeTemplate