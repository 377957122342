import * as React from "react"
import {Bouwtype} from "../../graphqlTypes";
import {Grid} from "@mui/material";
import {graphql} from "gatsby";
import Plattegrond from "../plattegrond";

interface Props {
    bouwtype: Bouwtype
}
const Plattegronden = ({bouwtype}: Props) => {
    const plattegronden = bouwtype.plattegronden || []
    return (
        <>
            {plattegronden.length > 0 &&
                <Grid
                    container
                    justifyContent={'center'}
                    spacing={2}
                >
                    {plattegronden?.map(plattegrond =>
                        <Grid
                            key={plattegrond.id}
                            item
                            xs={12}
                            md={6}
                            lg={6}
                            textAlign={'center'}
                        >
                            <Plattegrond plattegrond={plattegrond} />
                        </Grid>
                    )}
                </Grid>
            }
        </>
    )
}

export const query = graphql`
    fragment BouwtypePlattegronden on Bouwtype {
        plattegronden {
            ...Plattegrond
        }
    }
`

export default Plattegronden
