import * as React from "react"
import {Bouwtype} from "../../graphqlTypes";
import Oppervlakte from "../oppervlakte";
import {graphql} from "gatsby";
import Specificatie from "../specificatie";

interface Props {
    bouwtype: Bouwtype
}
const Specificaties = ({bouwtype}: Props) => {
    const woonOppervlakTot = bouwtype.algemeen?.woonoppervlakteTot
    const woonOppervlakVanaf = bouwtype.algemeen?.woonoppervlakteVanaf
    const perceelTot = bouwtype.algemeen?.perceeloppervlakteTot
    const perceelVanaf = bouwtype.algemeen?.perceeloppervlakteVanaf
    return (
        <>
            <Specificatie
                name={'Woonoppervlak'}
            >
                {woonOppervlakVanaf === woonOppervlakTot ?
                    <Oppervlakte oppervlakte={`${woonOppervlakVanaf}`}/>
                    :
                    <Oppervlakte oppervlakte={`${woonOppervlakVanaf} - ${woonOppervlakTot}`}/>
                }
            </Specificatie>
          {perceelVanaf &&
              <Specificatie name={'Perceel'}>
                {perceelVanaf === perceelTot ?
                  <Oppervlakte oppervlakte={`${perceelVanaf}`}/>
                  :
                  <Oppervlakte oppervlakte={`${perceelVanaf} - ${perceelTot}`}/>
                }
              </Specificatie>
          }
        </>
    )
}

export const query = graphql`
    fragment BouwtypeSpecificaties on Bouwtype {
        algemeen {
            perceeloppervlakteTot
            perceeloppervlakteVanaf
            woonoppervlakteTot
            woonoppervlakteVanaf
        }
    }
`

export default Specificaties
