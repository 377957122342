import React, {useState} from 'react';
import {ArrowLeft, ArrowRight} from "@mui/icons-material";
import {
    Box,
    IconButton
} from '@mui/material'
import {GatsbyImage, IGatsbyImageData} from "gatsby-plugin-image";

interface Props {
    items: IGatsbyImageData[]
}
export const Slide = ({items}: Props) => {

    const [currentPage, setCurrentPage] = useState(0)

    return (
        <Box display={'flex'} alignItems={'center'}>
            {currentPage > 1 &&
                <IconButton
                    onClick={() => setCurrentPage(page => page-1)}

                >
                    <ArrowLeft/>
                </IconButton>
            }
            <Box flexGrow={1}>
                <GatsbyImage image={items[currentPage]} alt={''} />
            </Box>
            {currentPage < items.length - 1 &&
                <IconButton
                    onClick={() => setCurrentPage(page => page+1)}
                >
                    <ArrowRight/>
                </IconButton>
            }
        </Box>
    )
}